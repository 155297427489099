<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物检疫</h3>
    <div class="detail">
      <h4 class="title">采购动物基本情况</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span>清洁等级：{{
            activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级'
          }}</span>
        <p>
          <span>雌性周龄：{{ female.weekDays }}周</span>
          <span>雌性体重：{{ female.weight }}g</span>
          <span>雌性数量：{{ female.count }}</span>
        </p>
        <p>
          <span>雄性周龄：{{ male.weekDays }}周</span>
          <span>雄性体重：{{ male.weight }}g</span>
          <span>雄性数量：{{ male.count }}</span>
        </p>
      </div>
      <el-form ref="addForm" size="small" :model="animal" class="addForm" label-suffix=":" :disabled="disabled"
               label-width="80px">
       <!-- <h3 class="tit">实验动物检疫结果</h3>-->


        <div class="flex  backReturn">
          <h4 class="title">实验动物检疫结果</h4>

          <el-button size="small" plain class="return" @click="quicklyAdd">快速录入</el-button>
        </div>


        <span class="qualified">合格动物：</span>

        <el-table
            :data="animal.qualified" border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
        >
          <el-table-column
              label="性别" width="60"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="耳号(必填)" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="耳号" required v-model="scope.row.overbit"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼架编号(必填)" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼架编号" v-model="scope.row.frameNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼舍编号(必填)" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼舍编号" v-model="scope.row.cageNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出生日期(必填)" >
            <template slot-scope="scope">
              <el-date-picker
                  size="small"
                  v-model="scope.row.birthDate"
                  type="date"
                  placeholder="出生日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <!--          <el-table-column label="数量(必填)">
                      <template slot-scope="scope">
                        <el-input size="small" type="number" :min="0" placeholder="数量" :max="scope.row.maxCount"
                                  v-model="scope.row.count"></el-input>
                      </template>
                    </el-table-column>-->
          <el-table-column label="观察状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="观察状态" v-model="scope.row.symptom"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="editColor">
                <el-button type="text" @click="copy(scope.row,scope.$index,0)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.$index,0)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="处理方式" prop="quaHandle">
          <el-input v-model="animal.quaHandle" clearable placeholder="请输入处理方式">
          </el-input>
        </el-form-item>
        <span class="qualified">不合格动物：</span>
        <el-table
            :data="animal.unqualified" border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="width: calc(100% - 12px);font-size:14px;color:#333">
          <el-table-column
              label="性别" width="60"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="耳号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="耳号" v-model="scope.row.overbit"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼架编号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼架编号" v-model="scope.row.frameNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼舍编号" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼舍编号" v-model="scope.row.cageNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出生日期" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-date-picker
                  size="small"
                  v-model="scope.row.birthDate"
                  type="date"
                  placeholder="出生日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <!--          <el-table-column label="数量">
                      <template slot-scope="scope">
                        <el-input size="small" placeholder="数量" type="number" :min="0" :max="scope.row.maxCount"
                                  v-model="scope.row.count"></el-input>
                      </template>
                    </el-table-column>-->
          <el-table-column label="观察状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="观察状态" v-model="scope.row.symptom"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="editColor">
                <el-button type="text" @click="copy(scope.row,scope.$index,1)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.$index,1)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="处理方式" prop="unquaHandle">
          <el-input v-model="animal.unquaHandle" clearable placeholder="请输入处理方式">
          </el-input>
        </el-form-item>
        <h3 class="tit">指定兽医</h3>
        <el-form-item label="兽医指定">
          <el-radio-group v-model="animal.reviewerName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.nickname" :key="item.userId"
                      @click.native="getAppId(item.userId,item.nickname)"
            > {{ item.nickname }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="fromSave">
        <template v-if="status==10 || status==11 || status ==14">
          <el-button type="primary" size="small" @click="submitBtn('addForm',1)">检疫完成</el-button>
          <el-button plain type="primary" size="small" @click="submitBtn('addForm',0)">保存
          </el-button>
        </template>
        <el-button plain type="primary" size="small" @click="exportInfo()">导出预览</el-button>
      </div>
    </div>

    <!--    新增编辑-->

    <el-dialog :close-on-click-modal="false"
               title="快捷添加动物"
               class="flex dialogForm"
               top="0"
               :visible.sync="dialogVisible"
               width="720px">
      <el-form ref="common" size="small" :model="common" class="addForm" label-suffix=":"
               label-width="140px">

        <div class="flex head">

          <el-form-item label="动物种类" prop="isQuality"
                        :rules="[{ required: true, message: '请输入动物种类', trigger: 'blur' }]">
            <el-select v-model="common.isQuality" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
     </el-option>
    </el-select>
          </el-form-item>
          <el-form-item label="性别" prop="gender"
                        :rules="[{ required: true, message: '请选择动物性别', trigger: 'blur' }]">
            <el-select v-model="common.gender" placeholder="请选择">
              <el-option
                  v-for="item in genderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="flex head">
        <el-form-item label="开始耳号" prop="startNum"
                        :rules="[{ required: true, message: '请输入开始耳号', trigger: 'blur' }]">
            <el-input v-model="common.startNum" placeholder="请输入开始耳号" type="number" :min="0"
                      class="smallWidth">
            </el-input>
          </el-form-item>
          <el-form-item label="结束耳号" prop="endNum"
                        :rules="[{ required: true, message: '请输入结束耳号', trigger: 'blur' }]">
            <el-input v-model="common.endNum" placeholder="请输入结束耳号" type="number" :min="0"
                      class="smallWidth">
            </el-input>
          </el-form-item>

        </div>

        <div class="flex head">

          <el-form-item label="笼架编号" prop="frameNo"
                        :rules="[{ required: true, message: '请输入笼架编号', trigger: 'blur' }]">
            <el-input v-model="common.frameNo" type="number" placeholder="请输入笼架编号" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="笼舍编号" prop="cageNo"
                        :rules="[{ required: true, message: '请输入笼舍编号', trigger: 'blur' }]">
            <el-input v-model="common.cageNo" type="number" placeholder="请输入笼舍编号" class="smallWidth">

            </el-input>
          </el-form-item>
        </div>


        <el-form-item label="出生日期">
          <el-date-picker
              size="small"
              v-model="common.birthDate"
              type="date"
              placeholder="请输入小鼠出生日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="观察状态" prop="symptom"
                      :rules="[{ required: true, message: '请输入小鼠观察状态', trigger: 'blur' }]">
          <el-input v-model="common.symptom" placeholder="请输入小鼠观察状态"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="quickAddForm('common')">确认</el-button>
          <el-button size="small" plain type="primary" @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>





  </div>
</template>

<script>

import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "quarantine",
  props: ["id", "status"],
  data() {
    return {
      dialogVisible:false,
      common:{
      },
      femaleOrderDetailId: 0,
    maleOrderDetailId : 0,
      options: [{
        value: '1',
        label: '合格动物'
      }, {
        value: '2',
        label: '不合格动物'
      }],
      genderOptions:
          [{
            value: '0',
            label: '雌性'
          }, {
            value: '1',
            label: '雄性'
          }],


      activeInfo: {},
      animal: {
        // 合格
        qualified: [],
        quaHandle: "",
        // 不合格
        unqualified: [],
        unquaHandle: "",
        reviewerId: null,
        reviewerName: ''
      },
      userList: [],
      disabled: false,
      readonly: false,
      quarState: null,
      quarId: null,
      // 雌性
      female: {},
      // 雄性
      male: {},
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    if (this.status == 10) {
      this.getType()
    } else {
      this.getQuarantine()
    }
    // 获取成员
    this.$get("/oauth/user/personal/" + this.user.companyId).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })
    // 动物基本信息
    this.$get("/subject/order/to/" + this.id).then(res => {
      if (res.status == 200) {
        // 数组赋值
        res.data.aniOrderDetailList.forEach(obj => {
          if (obj.gender == 0) {
            this.female = obj
          } else {
            this.male = obj
          }
        })
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })

  },
  methods: {

    // 提交接种信息
    quickAddForm(common) {
      this.$refs[common].validate((valid) => {
        if (valid) {

          let startNum = this.common.startNum;
          let endNum = this.common.endNum;
          let arr =[];

          for(var i= startNum; i<= endNum;i++){
            let animal = {};
            animal.overbit=i;
            animal.frameNo = this.common.frameNo
            animal.cageNo = this.common.cageNo
            animal.birthDate = this.common.birthDate
            animal.symptom = this.common.symptom
            animal.gender = this.common.gender
            if(this.common.gender==0){
              animal.orderDetailId = this.femaleOrderDetailId
            }
            if(this.common.gender==1){
              animal.orderDetailId = this.maleOrderDetailId
            }
            arr.push(animal)
          }

          if(this.common.isQuality==1){

            if(this.animal.qualified.length>0 && this.animal.qualified[0].overbit!=null && this.animal.qualified[0].overbit!=''){
              this.animal.qualified=this.animal.qualified.concat(arr)
            } else {
              this.animal.qualified=arr
            }

          }
          if(this.common.isQuality==2){

            if(this.animal.unqualified.length>0 && this.animal.unqualified[0].overbit!=null && this.animal.unqualified[0].overbit!=''){
              this.animal.unqualified =this.animal.qualified.concat(arr)
            } else {
              this.animal.unqualified =arr
            }


          }



        }
        this.common ={}
        this.dialogVisible=false
      })
    },








    quicklyAdd(row){

      this.action = '快速新增'
      this.dialogVisible = true
      this.vaccination = {}
    },
    getType() {
      let that = this;
      that.$get("/subject/order/to/" + this.id).then(res => {
        if (res.status == 200) {
          that.activeInfo = res.data

          // 数组赋值
          res.data.aniOrderDetailList.forEach(obj => {
            if(obj.gender==0){
              this.femaleOrderDetailId = obj.id
            }
            if(obj.gender==1){
              this.maleOrderDetailId = obj.id
            }
            let a = {
              orderDetailId: obj.id,
              gender: obj.gender,
              gene: obj.gene,
              overbit: "",
              frameNo: "",
              cageNo: "",
              birthDate:"",
              /*   maxCount: obj.count,
                 count: "",*/
              symptom: "",
            }
            this.animal.qualified.push(this.deepClone4(a))

            this.animal.unqualified.push(this.deepClone4(a))
          })

        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    // 获取检疫详情
    getQuarantine() {
      this.$get("/subject/quar/" + this.id).then(res => {
        if (res.status == 200) {
          // 检疫状态
          this.quarState = res.data.aniOrder.aniQuar.state
          // 检疫id
          this.quarId = res.data.aniOrder.aniQuar.id

          if (this.quarState == 1 || this.quarState == 2) {
            this.readonly = true
            this.disabled = true
          }
          this.activeInfo = res.data.aniOrder
          // 处理方式赋值
          this.animal.quaHandle = this.activeInfo.aniQuar.quaHandle
          this.animal.unquaHandle = this.activeInfo.aniQuar.unquaHandle

          // 赋值兽医
          this.animal.reviewerId = this.activeInfo.aniQuar.reviewerId
          this.animal.reviewerName = this.activeInfo.aniQuar.reviewerName

          res.data.aniOrder.quarResultList.forEach(obj => {
            // 筛选合格不合格
            if (obj.isQualify == 0) {
              this.animal.unqualified.push(this.deepClone4(obj))
            } else {
              this.animal.qualified.push(this.deepClone4(obj))
            }
          })
        }
      })
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    // 指定兽医
    getAppId(id, name) {
      this.animal.reviewerId = id
      this.animal.reviewerName = name
    },
    // 复制
    copy(val, index, ind) {
      if (ind == 0) {
        this.animal.qualified.splice(index, 0, JSON.parse(JSON.stringify(val)))
      } else {
        this.animal.unqualified.splice(index, 0, JSON.parse(JSON.stringify(val)))
      }
    },
    // 删除
    deleteA(index, ind) {
      if (ind == 0) {
        this.animal.qualified.splice(index, 1)
      } else {
        this.animal.unqualified.splice(index, 1)
      }
    },
    // 提交检疫
    submitBtn(task, state) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          let result = true
          let a = [], a1 = [], a2 = [], a3 = [];
          this.animal.qualified.forEach((item, index) => {
            let no = index + 1
            if (item.overbit == '') {
              result = result && false
              a.push('第' + no + '行：耳号不能为空')
              // this.$message.warning('第' + no + '行：耳号不能为空')
            }
            if (item.frameNo == '') {
              result = result && false
              a1.push('第' + no + '行：笼架编号不能为空')
            }
            if (item.cageNo == '') {
              result = result && false
              a2.push('第' + no + '行：笼舍编号不能为空')
            }
             if (item.birthDate == '') {
               result = result && false
               a3.push('第' + no + '行：出生日期不能为空')
             }
            item.isQualify = 1
          })
          if (!result) {
            if (a && a.length > 0) {
              return this.$message({message: "有" + a.length + '行耳号为空', type: 'warning'})
            }
            if (a1 && a1.length > 0) {
              return this.$message({message: "有" + a1.length + '行笼架编号为空', type: 'warning'})
            }
            if (a2 && a2.length > 0) {
              return this.$message({message: "有" + a2.length + '行笼舍编号为空', type: 'warning'})
            }
             if (a3 && a3.length > 0) {
               return this.$message({message: "有" + a3.length + '出生日期为空', type: 'warning'})
             }
          }
          this.animal.unqualified.forEach(item => {
            item.isQualify = 0
            /* if (item.count == '') {
               item.count = 0
             }*/
          })
          if (this.animal.reviewerId == null) {
            this.$message.warning("请选择指定兽医")
            return;
          }

          let arr = this.animal.qualified.concat(this.animal.unqualified)
          let data = {
            state: state,
            unquaHandle: this.animal.unquaHandle,
            quaHandle: this.animal.quaHandle,
            quarResultList: arr,
            subId: this.activeInfo.subId,
            reviewerId: this.animal.reviewerId,
            reviewerName: this.animal.reviewerName,
            inTimeStr: moment(this.activeInfo.inTime).format("yyyy-MM-DD HH:mm:ss")
          }

          let url;

          if (this.quarState == null) {
            url = this.$postJson('/ani/quar', data)
          } else {
            this.$set(data, "id", this.quarId)
            url = this.$putJson('/ani/quar', data)
          }
          url.then(res => {
            this.$message.success("提交成功")

            this.$router.go(-1)
          }).catch(err => {
            this.$message.error("检疫失败")
          })
        }
      })
    },
    exportInfo() {
      this.$emit('changeStatus', false, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";


</style>
